@import '../../../../scss/theme-bootstrap';

.minimal-header {
  &.site-header {
    .site-header {
      &__container {
        background-color: $color--white;
      }
      &__prefix {
        .site-logo {
          .site-logo__image {
            .icon--logo {
              fill: $color--black;
              width: 100%;
              height: 50px;
            }
          }
        }
      }
      &__tools {
        .site-my-mac {
          background-color: $color--white;
        }
      }
    }
  }
}
